import { QModal, useComponentApp } from "@qamf/lighthouse";
import { defineComponent, h } from "vue";

export function showModalFatal(title: string, description: string) {
	const errorModal = defineComponent({
		props: ["onHidden", "onClose"],
		setup(props) {
			return () => h(
				QModal,
				{
					modelValue: true,
					title,
					hideFooter: true,
					onHidden: props.onHidden,
					onClose: props.onClose
				},
				{
					default: () => description
				}
			);
		}
	});
	const { launch, dispose } = useComponentApp(errorModal, {
		onHidden: () => {
			dispose();
			return location.reload();
		},
		onClose: () => {
			dispose();
			return location.reload();
		}
	});
	launch();
}
