import { createRestClient } from "scarlett";

import useConnector from "../index";

const useRestClientBase = createRestClient({
	host: "",
	basePath: "",
	throw: true,
	credentials: "omit",
	mode: "cors",
	headers: new Headers({
		"Content-Type": "application/json",
		"X-ApiKey": "Tv9741AdiQTwospK5ki0X4NY4x6eV8xvaA", // FIXME: replace this when BE is ready (TS: #42881)
		"Access-Control-Allow-Origin": "*",
		"X-Identity-Username": "NeoversePWA"
	}),
	responseType: (_, res) => {
		if (res?.status === 500)
			return "text";

		return "json";
	}
});

const useCloudBackend = () => {
	const { useAppSettings } = useConnector();
	const { cloudBackendUrl } = useAppSettings();
	const baseClient = useRestClientBase();
	const host = __BACKEND_CLOUD_URL__ || cloudBackendUrl;

	if (!host || host.trim() === "")
		throw new Error("[REST BE URL] Missing Backend base Url option.");

	baseClient.setOption("host", host);

	return baseClient;
};

export default useCloudBackend;
