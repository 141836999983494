import type { ITranslation } from "@qamf/xliff-loader";
import debounce from "lodash-es/debounce";

import useConnector from "./connector/index";
import Languages from "./i18n/languages.json" assert { type: "json" };
import registerServiceWorker from "./registerServiceWorker";
import router from "./router";
import useEventsManager from "./utilities/events-manager";
import { showModalFatal } from "./utilities/modals";
import { AppInsightsEvent } from "./utilities/observability-events";
import usePersistentStorage from "./utilities/usePersistentStorage";

function initSettings() {
	const { initAppSettings } = useConnector();
	return initAppSettings({
		launchModeResolver() {
			return Promise.resolve(__LAUNCH_MODE__);
		},
		versionResolver() {
			return Promise.resolve(__VERSION__);
		},
		async settingsResolver() {
			const contextFileUrl = __LAUNCH_MODE__ === "Standard"
				? "/context.json"
				: `/settings/${__ENVIRONMENT__}-${__CHANNEL__}.json`;
			const response = await fetch(contextFileUrl, { method: "GET", cache: "no-cache" });
			const res = await response.json().catch(e => console.warn(e));
			return res;
		}
	});
}

async function resolveAppContext() {
	const { useAppSettings, useSystemStore, use4DAgent, useCloudBackend } = useConnector();
	const { setOption: setAgent4DOption } = use4DAgent();
	const { setOption: setCloudOption } = useCloudBackend();
	const { setId: setSystemId } = useSystemStore();
	const { getItem } = usePersistentStorage();

	const storageTerminalAuthInfo = getItem("terminalAuthInfo");
	const lanIpAddress = storageTerminalAuthInfo?.LanIpAddress ?? null;
	const systemId = storageTerminalAuthInfo?.CenterId ?? null;

	const { launchMode, cloudBackendUrl } = useAppSettings();

	if (launchMode === "DevelopSession" && __CENTER_ID__)
		setSystemId(+__CENTER_ID__);
	else if (systemId)
		setSystemId(+systemId);

	setCloudOption("host", cloudBackendUrl);

	if (lanIpAddress)
		setAgent4DOption("host", `https://${lanIpAddress}:5253`); // FIXME: move port number to useAppSettings() (TS: #46452)
}
async function setupObservability() {
	const { useAppSettings, useObservability } = useConnector();
	const { setup, setCommonPropsResolver } = useObservability();
	const { appInsightsConnString } = useAppSettings();

	setup({
		config: {
			connectionString: appInsightsConnString,
			maxAjaxCallsPerView: -1,
			disableExceptionTracking: true,
			disableTelemetry: __LAUNCH_MODE__ !== "Standard"
		}
	});
	setCommonPropsResolver(function commonObservabilityProps() {
		const { useSystemStore } = useConnector();
		const route = router.currentRoute;
		const { launchMode, version, channel, environment, cloudBackendUrl } = useAppSettings();
		const { Id: systemId } = useSystemStore();
		const storageTerminalAuthInfo = usePersistentStorage().getItem("terminalAuthInfo");
		const agent4DUrl = storageTerminalAuthInfo?.LanIpAddress ?? null;
		const commonProperties = {
			routePath: route.value.fullPath ?? "",
			routeName: route.value.name ?? "",
			launchMode,
			channel,
			environment,
			version,
			cloudBackendUrl,
			agent4DUrl,
			systemId: systemId.value
		};
		return commonProperties;
	});
}

function initErrorHandling() {
	const fatalErrors: any[] = [];
	const logErrors = debounce(() => {
		const printErrorEl = (el: any, title?: string) => {
			console.groupCollapsed("\x1b[31m%s\x1b[0m", title ?? el.error.message);
			console.info(el.datetime);
			console.error(el.error);
			if (el.extras)
				console.info(el.extras);
			console.groupEnd();
		};
		if (fatalErrors.length === 1)
			printErrorEl(fatalErrors[0], "A fatal error occurred");

		else {
			console.groupCollapsed("\x1b[31m%s\x1b[0m", `Intercepted fatal errors: ${fatalErrors.length}`);
			fatalErrors.forEach(er => printErrorEl(er));
			console.groupEnd();
		}
	}, 1000);
	const { useObservability, useI18n } = useConnector();
	const { trackException, trackEvent } = useObservability();
	const { onError, emitError } = useEventsManager();
	const { translateKey } = useI18n();
	onError(async(error, extras) => {
		const tracked = await trackException(error, extras);
		fatalErrors.push({ error, extras, datetime: new Date() });
		logErrors();

		trackEvent(AppInsightsEvent.FatalError, {
			description: "Unhandled exception feedback to user",
			error: tracked?.exception,
			errorName: tracked?.exception.name,
			errorStack: tracked?.exception.stack,
			errorMessage: tracked?.exception.message,
			errorExtras: tracked?.properties
		}, true);

		showModalFatal(translateKey("modal_fatal_error_title"), translateKey("modal_fatal_error_desc"));
	});
	window.onerror = (message, source, lineno, colno, error) => {
		if (!error) return;

		emitError(error, { message, source, lineno, colno });
		return true;
	};
	window.onunhandledrejection = event => {
		event.preventDefault();
		if (event.reason instanceof Error) emitError(event.reason);
		else emitError(new Error("Promise Rejection"), event.reason);
	};
}

async function initMultiLanguage() {
	const { useI18n } = useConnector();
	const { setup: setupI18n, setLanguage } = useI18n();
	setupI18n<ITranslation>({
		languages: Languages,
		languageFetcher(langCode) {
			console.log(`[i18n] Setting lang code: ${langCode}`);
			switch (langCode) {
				case "de-DE":
					return import("@qamf/qst-mod-neoverse/de-DE/neoverse.xlf");
				case "fr-FR":
					return import("@qamf/qst-mod-neoverse/fr-FR/neoverse.xlf");
				case "it-IT":
					return import("@qamf/qst-mod-neoverse/it-IT/neoverse.xlf");
				case "es-ES":
					return import("@qamf/qst-mod-neoverse/es-ES/neoverse.xlf");
				case "nb-NO":
					return import("@qamf/qst-mod-neoverse/nb-NO/neoverse.xlf");
				case "sv-SE":
					return import("@qamf/qst-mod-neoverse/sv-SE/neoverse.xlf");
				case "da-DK":
					return import("@qamf/qst-mod-neoverse/da-DK/neoverse.xlf");
				case "id-ID":
					return import("@qamf/qst-mod-neoverse/id-ID/neoverse.xlf");
				case "nl-NL":
					return import("@qamf/qst-mod-neoverse/nl-NL/neoverse.xlf");
				case "ja-JP":
					return import("@qamf/qst-mod-neoverse/ja-JP/neoverse.xlf");
				default:
					return import("./i18n/locale.xlf");
			}
		},
		translateKeyResolver(repo, key) {
			if (!repo) {
				console.warn(`[i18n] The Translation-Repository is not defined. Returning the key as is: ${key}`);
				return key;
			}
			let translateKey = "";
			if (key in repo.target && repo.target[key])
				translateKey = repo.target[key].trim();
			else if (key in repo.source && repo.source[key])
				translateKey = repo.source[key].trim();
			else {
				console.warn(`[i18n] The Translation-Key '${key}' was not translated`);
				translateKey = `[${key.toUpperCase()}]`;
			}
			if (!translateKey)
				console.warn(`[i18n] The Translation-Key '${key}' is empty.`);
			return translateKey;
		}
	});
	await setLanguage("en-US");
}
async function registerModules() {
	const { registerModule, useObservability } = useConnector();
	const { trackEvent } = useObservability();

	trackEvent(AppInsightsEvent.ModuleRequest, { moduleId: "agent4d" });
	const modAgent4D = await import("@mod-neoverse");
	trackEvent(AppInsightsEvent.ModuleDownloaded, { moduleId: "agent4d" });

	trackEvent(AppInsightsEvent.ModuleSetupStart, { moduleId: "agent4d" });
	const { component } = await registerModule({
		appName: "Neoverse",
		appVersion: __VERSION__,
		basePath: "/agent4d",
		useConnector
	}, modAgent4D);
	router.addRoute({
		path: "/agent4d/*",
		name: "Neoverse",
		component,
		meta: {
			requiresAuth: true
		}
	});
	trackEvent(AppInsightsEvent.ModuleSetupEnd, { moduleId: "agent4d" });
}

function initFunctionalities() {
	const { useSecurity } = useConnector();
	const { setup } = useSecurity();
	const userFunctionalities = {
		AccessNeoverseModule: {
			Enabled: true,
			Experimental: false
		}
	};
	setup(userFunctionalities);
}

export default async function boot() {
	await initSettings();
	await resolveAppContext();
	setupObservability();
	initErrorHandling();
	await initMultiLanguage();
	registerServiceWorker();
	initFunctionalities();
	await registerModules();
}
