import { defineConnector } from "@qamf/modules-connector";

import supportedLanguages from "../i18n/languages.json";
import use4DAgent from "./agent4D";
import useCloudBackend from "./cloud-rest";

type SupportedLanguagesType = typeof supportedLanguages;
export type AppLanguageCodes = keyof SupportedLanguagesType;

const useConnector = defineConnector({
	useCloudBackend,
	use4DAgent
});
export default useConnector;
