type StorageData = {
	terminalAuthInfo: {
		AuthComplete?: boolean,
		CenterId: number,
		LanIpAddress: string
	}
}

type StorageCategory = keyof StorageData;

export default function usePersistentStorage() {
	return {
		setItem<T extends StorageCategory>(name: T, value: StorageData[T]): void {
			localStorage.setItem(name, JSON.stringify(value));
		},
		getItem<T extends StorageCategory>(name: T): StorageData[T] | null {
			const item = localStorage.getItem(name);
			return item ? JSON.parse(item) : null;
		},
		removeItem(name: StorageCategory): void {
			localStorage.removeItem(name);
		}
	};
}
