import { QButton, QModal, QNavbar, QOverlay } from "@qamf/lighthouse";
import { computed, defineComponent, onMounted, ref } from "vue";

import QSettings from "./components/q-settings/q-settings.vue";
import useConnector from "./connector";
import { useRouter } from "./router";
import { AppInsightsEvent } from "./utilities/observability-events";
import usePersistentStorage from "./utilities/usePersistentStorage";

export default defineComponent({
	name: "NeoverseApp",
	components: {
		QOverlay,
		QNavbar,
		QSettings,
		QButton,
		QModal
	},
	setup() {
		const { useAppSettings, useObservability, useI18n, useAppStore, useSystemStore } = useConnector();
		const { environment } = useAppSettings();
		const { trackTrace, trackEvent } = useObservability();
		const { translateKey } = useI18n();
		const appStore = useAppStore();
		const { setId } = useSystemStore();
		const { getItem, removeItem } = usePersistentStorage();

		const appVersion = __VERSION__;

		const deferredPrompt = ref<any>(null);
		const showSettings = ref(false);
		const isPopupOpen = ref(false);
		const areSettingsEnabled = ref(environment);
		const isOffline = computed(() => appStore.isOffline.value);
		const storageTerminalAuthInfo = getItem("terminalAuthInfo");
		const isAuthComplete = storageTerminalAuthInfo?.AuthComplete ?? false;

		const router = useRouter();
		const modules = router?.getRoutes().filter(r => !r.meta?.notShowing).reverse();
		const openSettings = () => {
			showSettings.value = true;
		};

		const logOut = () => {
			setId(null);
			removeItem("terminalAuthInfo");

			router.push({ name: "login" });
		};

		onMounted(() => {
			window.addEventListener("beforeinstallprompt", (event) => {
				event.preventDefault();
				deferredPrompt.value = event;
				trackTrace({
					message: "Prompt: 'beforeinstallprompt' event was fired.",
					severityLevel: 1
				});
			});

			window.addEventListener("appinstalled", () => {
				trackTrace({
					message: "Prompt: PWA was installed.",
					severityLevel: 1
				});
				trackEvent(AppInsightsEvent.PwaInstalled);
				deferredPrompt.value = null;
			});
		});

		return {
			areSettingsEnabled,
			appVersion,
			modules,
			isPopupOpen,
			openSettings,
			showSettings,
			translateKey,
			isOffline,
			isAuthComplete,
			logOut
		};
	}
});
